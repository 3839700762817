import React, { useEffect, useState } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { callMsGraph } from '../../utils/MsGraphApiCall';
import { Main } from '../content/Main';
import { isResponseOk } from '../../utils/Util';
import { msalInstance } from '../../../..';


export const GRAPH_ENDPOINTS = {
  ME: "https://graph.microsoft.com/v1.0/me",
  MAIL: "https://graph.microsoft.com/v1.0/me/messages",
  PHOTO: "https://graph.microsoft.com/v1.0/me/photo/$value",
  // {oid} path parameter in APPROLES must be replaced with actual objectId
  APPROLES: "https://graph.microsoft.com/v1.0/users/{oid}/appRoleAssignments" 
};
export interface GraphData {
  profile?: any;
  photo?: string;
  roles: string[];
  appRoles: string[];
}

const Landing = () => {
  const { inProgress } = useMsal();
  const [ graphData, setGraphData ] = useState<null|GraphData>(null);
  const account = msalInstance.getActiveAccount();

  useEffect(() => {
      if (!graphData && inProgress === InteractionStatus.None) {
        const promises = [
          callMsGraph(GRAPH_ENDPOINTS.ME, false),
          callMsGraph(GRAPH_ENDPOINTS.PHOTO, false),
          callMsGraph(GRAPH_ENDPOINTS.APPROLES.replace('{oid}', account.localAccountId), false)
        ];
        Promise.allSettled(promises).then((responses: any) => {
          const profile = isResponseOk(responses[0]) ?
          {
            profile: responses[0].value
          } : {};
          const photo = isResponseOk(responses[1]) ? (() => {
            const url = window.URL || window.webkitURL; 
            const blobUrl = url.createObjectURL(responses[1].value);
            return {
              ...profile,
              photo: blobUrl
            };
          })() : profile;

          const graphData = {
            ...photo,
            roles: [],
            appRoles: responses[2].value?.value
          };
          setGraphData(graphData);
        });
      }
  }, [inProgress, graphData]);

  return (
      <>
          { 
            graphData ?
            <Main graphData={graphData} /> :
            <LinearProgress />
          }
      </>
  );
};

export {
  Landing
};