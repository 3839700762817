import { loginRequest, externalRequest } from "../authConfig";
import { msalInstance } from "../../../index";

export async function callMsGraph(url: string, external: boolean) {
    const account = msalInstance.getActiveAccount();
    if (!account) {
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    const login = external ? externalRequest : loginRequest;

    const response = await msalInstance.acquireTokenSilent({
        ...login,
        account: account
    });

    const headers = new Headers();
    const bearer = `Bearer ${response.accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch(url, options)
        .then(response => {
            const contentType = response.headers.get('content-type');
            if (contentType.startsWith('image/')) {
                return response.blob()
            } else {
                return response.json();
            }
        }).catch(error => console.log(error));
}