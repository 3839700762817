import React from 'react'
import './style.css';

export const ChangelogMarkdown = () => {
    return (
        <div className='markdown-body' style={{
            boxSizing: 'border-box',
            minWidth: 200,
            maxWidth: 980,
            margin: '0 auto',
            padding: 15
          }}>
            <h1>ROAMES App Hub Release Notes</h1>
<p>All notable changes to this project will be documented in this file.</p>
<h2>
<a className="anchor" href="#1.0.2" id="1.0.2">
<svg aria-hidden="true" className="octicon octicon-link" height="16" version="1.1" viewbox="0 0 16 16" width="16">
  <path d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path>
</svg>
</a>[1.0.2] - 2022-11-18</h2>
<p>This release is an improvement to the existing clustering and classification system in use within ROAMES®, resulting in greatly improved quality of clusters and intrusions displayed in both ROAMES® World and ROAMES® Analytics. Improved Artificial Intelligence/Machine Learning approaches will provide a much more accurate result at scale, and address areas of low performance in the past. In particular, the accuracy of vegetation and buildings clusters is greatly improved. This will lead to more actionable intrusion data in all ROAMES® deliverables.</p>
<h3>
<a className="anchor" href="#1.0.2-added" id="1.0.2-added">
<svg aria-hidden="true" className="octicon octicon-link" height="16" version="1.1" viewbox="0 0 16 16" width="16">
  <path d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path>
</svg>
</a>Added</h3>
<ul>
<li>New point classification process run on each bay. Automatically identify:
<ul>
<li>Vegetation</li>
<li>Building</li>
<li>Network</li>
<li>Ground</li>
<li>Noise</li>
</ul>
</li>
</ul>
<h3>
<a className="anchor" href="#1.0.2-changed" id="1.0.2-changed">
<svg aria-hidden="true" className="octicon octicon-link" height="16" version="1.1" viewbox="0 0 16 16" width="16">
  <path d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path>
</svg>
</a>Changed</h3>
<ul>
<li>Clustering performed after classification to avoid mixed class clusters.</li>
<li>Clustering algorithm improved to avoid over segmentation.</li>
</ul>
<h3>
<a className="anchor" href="#1.0.2-fixed" id="1.0.2-fixed">
<svg aria-hidden="true" className="octicon octicon-link" height="16" version="1.1" viewbox="0 0 16 16" width="16">
  <path d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path>
</svg>
</a>Fixed</h3>
<ul>
<li>New classification and improvements to clustering have greatly reduced the following problems:
<ul>
<li>Low lying vegetation being grouped together into large clusters.</li>
<li>Single trees becoming segmented into multiple clusters.</li>
<li>Fence and building clusters incorrectly grouped with vegetation.</li>
<li>Stray noise points incorrectly grouped with vegetation</li>
</ul>
</li>
</ul>
<p>This release functionality will be available for newly processed campaigns after November 18, 2022.  All clients will be upgraded to the new system on commencement of your next cycle. Please speak to your Project Manager if you would like to upgrade earlier, or if you just want to find out more about this improvement.</p>
<h2>
<a className="anchor" href="#1.0.1" id="1.0.1">
<svg aria-hidden="true" className="octicon octicon-link" height="16" version="1.1" viewbox="0 0 16 16" width="16">
  <path d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path>
</svg>
</a>[1.0.1] - 2021-08-31</h2>
<h3>
<a className="anchor" href="#1.0.1-changed" id="1.0.1-changed">
<svg aria-hidden="true" className="octicon octicon-link" height="16" version="1.1" viewbox="0 0 16 16" width="16">
  <path d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path>
</svg>
</a>Changed</h3>
<ul>
<li>Updated ROAMES World URL.</li>
<li>Updated news items.</li>
</ul>
<h2>
<a className="anchor" href="#1.0.0" id="1.0.0">
<svg aria-hidden="true" className="octicon octicon-link" height="16" version="1.1" viewbox="0 0 16 16" width="16">
  <path d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path>
</svg>
</a>[1.0.0] - 2021-07-09</h2>
<h3>
<a className="anchor" href="#1.0.0-added" id="1.0.0-added">
<svg aria-hidden="true" className="octicon octicon-link" height="16" version="1.1" viewbox="0 0 16 16" width="16">
  <path d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path>
</svg>
</a>Added</h3>
<ul>
<li>Initial version of the app hub.</li>
</ul>

        </div>
    )
}
