import React from "react";

const OverviewForAnalytics = () => {
  return (
    <div className="product-overview">
      <div className="top-context">
        <div className="icon-wrapper">
          <a
            className="icon icon-insert_chart"
            href={process.env.REACT_APP_ROAMES_ANALYTICS_URL}
            target="_blank"
            rel="noreferrer"
            aria-label="Launch Application"
          >{null}</a>
        </div>
        <div className="application-name">ROAMES Analytics</div>
        <div className="application-description">
          Provides access to all data allowing you to understand your network
          and rapidly prioritise associated risks. Ability to export or link to
          ROAMES World for spatial context.
        </div>
      </div>
      <div className="product-overview-blob">
        <p>
          Often approaching millions of records, an asset register and any
          associated spatial data is typically too large to analyze at scale
          using traditional GIS or enterprise systems alone. As a result, it is
          difficult or impossible to detect broad trends, rapidly investigate an
          outlier, or share access with any users in any location. ROAMES
          Analytics addresses this need by offering a high-performance data
          investigation tool with a simple, intuitive interface so you can get
          better answers faster – critical for an optimized approach to asset
          and vegetation management.
        </p>
      </div>
      <div className="product-overview-list-title">
        With ROAMES Analytics, you can:
      </div>
      <ul className="product-overview-list">
        <li>
          Intuitive interface to quickly investigate assets or areas of interest
        </li>
        <li>Access all asset records at once including multiple cycles</li>
        <li>Access reported business rule violations</li>
        <li>Filter, sort, query, pin columns</li>
        <li>View selected records directly in ROAMES World</li>
        <li>Configure custom views</li>
        <li>Copy/paste with headers</li>
        <li>Export to CSV and KML</li>
      </ul>
    </div>
  );
};

const OverviewForRoamesWorld = () => {
  return (
    <div className="product-overview">
      <div className="top-context">
        <div className="icon-wrapper">
          <a
            className="icon icon-roames_world"
            href={process.env.REACT_APP_ROAMES_WORLD_URL}
            target="_blank"
            rel="noreferrer"
            aria-label="Launch Application"
          >{null}</a>
        </div>
        <div className="application-name">ROAMES World</div>
        <div className="application-description">
          Provides access to spatially accurate 3D models, point clouds and
          imagery. Shows Assets and Vegetation in context for effective risk
          management.
        </div>
      </div>
      <div className="product-overview-blob">
        <p>
          With ROAMES World, Fugro hosts the remote sensing data collected for
          your program and serves geodata layers securely over the web, saving
          you capital investment in data hosting, ongoing operational overheads,
          and the cost of employing in-house specialists.Access to ROAMES World
          can be shared with all infrastructure owners and agencies, creating a
          resilient sustainable community and collaborative approach to asset
          management.
        </p>
        <p>
          In ROAMES World, quickly render massive 3D lidar point clouds with a
          wide range of tools allowing you to manipulate point colorisation,
          decimation, point size, occlusion, height limits and more.
          Sophisticated navigation tools allow you to perform ‘fly-through’ or
          ‘walk mode’ visualisation and 2D and Google Street View base layers
          offer relevant context.
        </p>
        <p>
          ROAMES World offers many additional features including the ability to
          import corporate data in .shp or .kml files, bookmark specific
          locations, take high resolution screenshots, measure features, and
          more.
        </p>
        <p>
          The Fugro ROAMES product suite is built in Amazon Web Services (AWS),
          allowing for an agile response in meeting customer and market
          requirements for dynamic, powerful solutions. Fugro is an AWS Select
          Technology Partner and is well-positioned to leverage the rapid pace
          of technology advancements offered by the world’s most comprehensive
          and adopted cloud platform.
        </p>
      </div>
      <div className="product-overview-list-title">
        Key benefits of ROAMES World:
      </div>
      <ul className="product-overview-list">
        <li>
          Intuitive interface to quickly investigate assets or areas of interest
        </li>
        <li>
          Common source of truth with ‘digital twin’ representation of network
        </li>
        <li>
          Secure collaboration creating better alignment of critical
          infrastructure and regional planning
        </li>
        <li>Accelerated response to emergencies or natural disasters</li>
        <li>A true representation of reality with spatial accuracies</li>
        <li>
          Reduced costs of infrastructure management, storing, accessing, and
          processing data
        </li>
      </ul>
    </div>
  );
};

const OverviewForDataHub = () => {
  return (
    <div className="product-overview">
      <div className="top-context">
        <div className="icon-wrapper">
          <a
            className="icon icon-roames_world"
            href={process.env.REACT_APP_DATAHUB_URL}
            target="_blank"
            rel="noreferrer"
            aria-label="Launch Application"
          >{null}</a>
        </div>
        <div className="application-name">DataHub</div>
        <div className="application-description">
          Analytics DataHub is a system to store and organise processing of ROAMES data in a cheap and highly scalable way.
          It is build from almost vanilla AWS offering to leverage their development efforts and reduce ours
        </div>
      </div>
      <div className="product-overview-blob">
        <p>
          Major principles used in the design of the system are:

          High-performance serverless compute layer.
          Low-cost highly scalable storage.
          Independent data processing workflows.
          High observability and rich tooling.
          All possible DevOps practices: automated deployments, automated testing, infrastructure-as-code

          Key elements used for implementation are:

          Data lake on S3 with AWS Glue Data Catalog to keep metadata.
          EventBridge and Step Functions to manage workflows and executions.
          AWS Glue as an ETL and data processing engine.
          AWS Lambda for serverless routines.
        </p>
        <p>
          The Fugro ROAMES product suite is built in Amazon Web Services (AWS),
          allowing for an agile response in meeting customer and market
          requirements for dynamic, powerful solutions. Fugro is an AWS Select
          Technology Partner and is well-positioned to leverage the rapid pace
          of technology advancements offered by the world’s most comprehensive
          and adopted cloud platform.
        </p>
      </div>
    </div>
  );
};

export { OverviewForAnalytics, OverviewForRoamesWorld, OverviewForDataHub };
