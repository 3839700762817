import { Routes } from "../main/ts/components/routing/Routes";
import { OverviewForAnalytics, OverviewForDataHub, OverviewForRoamesWorld } from "./Overview";

export interface Application {
  image: string;
  name: string;
  description: string;
  icon: string;
  link: string;
  releaseNotes?: string;
  overview?: () => JSX.Element;
  getstarted?: string;
  download?: string;
  appRole?: string
}

const applications = [
  {
    image: "analytics",
    name: "ROAMES Analytics",
    description:
      "Provides access to all data allowing you to understand your network and rapidly prioritise associated risks. Ability to export or link to ROAMES World for spatial context.",
    icon: "insert_chart",
    link: process.env.REACT_APP_ROAMES_ANALYTICS_URL,
    releaseNotes: Routes.SUPPORT_RELEASENOTES_ANALYTICS,
    getstarted: Routes.SUPPORT_GETSTARTED_ANALYTICS,
    overview: OverviewForAnalytics,
  },
  {
    image: "roamesworld",
    name: "ROAMES World",
    description:
      "Provides access to spatially accurate 3D models, point clouds and imagery. Shows Assets and Vegetation in context for effective risk management.",
    icon: "roames_world",
    link: process.env.REACT_APP_ROAMES_WORLD_URL,
    releaseNotes: Routes.SUPPORT_RELEASENOTES_WORLD,
    getstarted: Routes.SUPPORT_GETSTARTED_WORLD,
    download:
      "https://roames-global-service-support.s3-ap-southeast-2.amazonaws.com/Roames%20World/Roames%20World%202020.1.01/RoamesWorld-Windows-2020.1.01.exe", // TODO: Parameterize this?
    overview: OverviewForRoamesWorld,
  },
  {
    image: "default",
    name: "DataHub",
    description:
      "Analytics DataHub is a system to store and organise processing of ROAMES data in a cheap and highly scalable way. It is build from almost vanilla AWS offering to leverage their development efforts and reduce ours",
    icon: "roames_world",
    link: process.env.REACT_APP_DATAHUB_URL,
    overview: OverviewForDataHub,
    appRole: process.env.REACT_APP_DATAHUB_APPROLE_ID
  },
];

export { applications };
